import {
    GETOFFERS,
    GETOFFERS_ERROR,
    GETOFFERS_CLEAN,
    GETOFFERS_CLEAN_ERROR,
    SET_LOADING_UUID,
} from "_models/redux/types";

const initialState = {
    getOffersObj: [],
    loading: false,
    isLoadingUUDI: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GETOFFERS:
            return {
                ...state,
                getOffersObj: action.payload,
                loading: true,
            };
        case GETOFFERS_ERROR:
            return {
                ...state,
                getOffersObj: action.payload,
                loading: false,
            };
        case GETOFFERS_CLEAN:
        case GETOFFERS_CLEAN_ERROR:
            return {
                ...state,
                getOffersObj: action.payload,
                loading: false,
            };
        case SET_LOADING_UUID: {
            return {
                ...state,
                isLoadingUUDI: action.payload,
            };
        }

        default:
            return state;
    }
}
