import React, { useEffect } from "react";

import { connect } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { Button, Grid } from "@mui/material";

import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import NoCompatiblePurchaseImage from "assets/images/purchase/no-compatible.svg";

import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";

const NoCompatiblePurchasePage = (props) => {
    const navigate = useNavigate();

    const { state } = useLocation();
    const tokenView = typeof state !== "undefined" ? state?.token : undefined;

    const { cleanCheckImeiAction } = props || {};

    const checkImeiMethod = () => {
        navigate(`/purchase/compatibility`, {
            state: { dataToken: tokenView },
        });
    };

    useEffect(() => {
        return () => {
            cleanCheckImeiAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid className={"main-page"} minHeight="100dvh">
            <Grid item className={"main-component-container"}>
                <Grid className={"main-component"}>
                    <Grid item className={"main-form no-bg"}>
                        <Grid
                            item
                            xs={12}
                            className="purchase-form no-compatible"
                        >
                            <form>
                                <Grid item xs={12} className="centered">
                                    <h2>Lo sentimos...</h2>
                                    <h3>
                                        Tu equipo no es compatible con Dalefon
                                    </h3>
                                    <img
                                        src={NoCompatiblePurchaseImage}
                                        className={"main-image m-tb-20"}
                                    />
                                    <p>
                                        Te recomendamos probar con un equipo
                                        diferente
                                    </p>

                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            variant="contained"
                                            className={"main-button"}
                                            onClick={checkImeiMethod}
                                        >
                                            Probar un equipo diferente
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

// export default NoCompatiblePurchasePage;

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoCompatiblePurchasePage);
