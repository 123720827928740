import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { useNavigate, useLocation } from "react-router-dom";

import { Button, Grid } from "@mui/material";

import purchaseImage from "assets/images/purchase/purchase.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import WhatsappImage from "assets/images/template/whatsapp.png";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";

import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";

const ComprobarCompatibilidadPurchasePage = (props) => {
    const navigate = useNavigate();

    const { state } = useLocation();
    const tokenView = typeof state !== "undefined" ? state?.token : undefined;
    const imei = typeof state !== "undefined" ? state?.imeiValue : "---";

    const { cleanCheckImeiAction } = props || {};

    const [whastappUrl, setWhastappUrl] = useState();

    const checkImeiMethod = () => {
        navigate(`/purchase/compatibility`, {
            state: { dataToken: tokenView },
        });
    };

    useEffect(() => {
        setWhastappUrl(
            // encodeURI(
            "https://api.whatsapp.com/send/?phone=5215586483831&text=Hola, Dalefon. Al parecer necesitan más información de mi equipo para ver si soy compatible con ustedes, ¿me podrían ayudar por favor? Mi IMEI es: " +
                imei +
                "&type=phone_number&app_absent=0"
            // )
        );

        return () => {
            cleanCheckImeiAction();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid conainer minHeight="100dvh">
            <Grid item sx={{ textAlign: "center" }}>
                <img
                    src={purchaseImage}
                    className={"main-image"}
                    style={{ maxWidth: "500px", width: "100%" }}
                />
            </Grid>

            <Grid
                item
                className="purchase-form no-bg"
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                }}
            >
                <form
                    style={{
                        maxWidth: "600px",
                    }}
                >
                    <Grid item xs={12} className="centered">
                        <h2>¡Ups!</h2>
                        <p>
                            Parece que necesitamos más información de tu equipo.
                        </p>

                        <p>
                            Para brindarte el mejor servicio por favor ponte en
                            contacto con nosotros para revisar la compatibilidad
                            de tu dispositivo.
                        </p>
                    </Grid>

                    <Grid item xs={12} className="centered">
                        <h3>Llámanos</h3>
                        <Grid container>
                            <Grid item xs={6} className="compatibility">
                                <p className="phones">
                                    <span>
                                        <WifiCalling3Icon
                                            sx={{ color: "white" }}
                                        />
                                    </span>{" "}
                                    800 649 0754
                                </p>
                            </Grid>
                            <Grid item xs={6} className="compatibility">
                                <p className="phones">
                                    <span>
                                        {" "}
                                        <WifiCalling3Icon
                                            sx={{ color: "white" }}
                                        />
                                    </span>{" "}
                                    777 102 1804
                                </p>
                            </Grid>
                        </Grid>

                        <p>O escríbenos a</p>
                        <a href={whastappUrl}>
                            <img
                                src={WhatsappImage}
                                className={"whatsapp-image"}
                            />
                        </a>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={"main-button"}
                                onClick={checkImeiMethod}
                            >
                                Probar un equipo diferente
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <FormFooterComponent />
        </Grid>
    );
};

// export default ComprobarCompatibilidadPurchasePage;

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ComprobarCompatibilidadPurchasePage);
