import {
    PRODUCTITEM,
    PRODUCTITEM_ERROR,
    SET_LOADING_UUID,
} from "_models/redux/types";

const initialState = {
    productItemgObj: [],
    loading: false,
    isLoadingUUDI: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PRODUCTITEM:
            return {
                ...state,
                productItemgObj: action.payload,
                loading: true,
            };
        case PRODUCTITEM_ERROR:
            return {
                ...state,
                productItemgObj: action.payload,
                loading: false,
            };
        case SET_LOADING_UUID: {
            return {
                ...state,
                isLoadingUUDI: action.payload,
            };
        }
        default:
            return state;
    }
}
