import { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    getOffersAction,
    setLoadingUUIDAction,
} from "_models/redux/get-offers/action";
import { useLocation, useNavigate } from "react-router-dom";
import { useProductsCardsHook } from "utils/components/flows-products/useProductsCardsHook";
import { useFlowsProductsHook } from "utils/components/flows-products/useFlowsProductsHook";
import FlowsProductsComponent from "utils/components/flows-products/FlowsProductsComponent";
import { cleanGetOffersAction } from "_models/redux/get-offers/clean";
import { Backdrop, CircularProgress } from "@mui/material";

const PurchaseProductsPage = (props) => {
    const history = useNavigate();
    const { state } = useLocation();

    const {
        getOffers,
        getOffersAction,
        cleanGetOffersAction,
        setLoadingUUIDAction,
    } = props;

    const { getOffersObj, isLoadingUUDI } = getOffers || "undefined";
    const { data } = getOffersObj || "undefined";
    const { products } = data || "undefined";

    const [tabIndex, setTabIndex] = useState(0);
    const [tabSubcategory, setTabSubcategory] = useState(0);

    const [errorMessage, setErrorMessage] = useState();

    const [loadingCircular, setLoadingCircular] = useState(false);

    /*** Arreglos de subcategorías de los productos ***/

    const [classicSorted, setClassicSorted] = useState([]);
    const [slimSorted, setSlimSorted] = useState([]);
    const [fitSorted, setFitSorted] = useState([]);
    const [liteSorted, setLiteSorted] = useState([]);

    const [shareSorted, setShareSorted] = useState([]);
    const [notShareSorted, setNotShareSorted] = useState([]);

    const params = {
        state,
        getOffers,
        products,
        tabIndex,
        setTabIndex,
        tabSubcategory,
        setTabSubcategory,
        errorMessage,
        setErrorMessage,
        loadingCircular,
        setLoadingCircular,
        classicSorted,
        setClassicSorted,
        slimSorted,
        setSlimSorted,
        fitSorted,
        setFitSorted,
        liteSorted,
        setLiteSorted,
        shareSorted,
        setShareSorted,
        notShareSorted,
        setNotShareSorted,
        getOffersAction,
    };

    const { handleTabChange, handleSubcategoryChange } =
        useFlowsProductsHook(params);

    useEffect(() => {
        if (getOffersObj?.response?.status === 401) {
            history("/purchase");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getOffersObj]);

    useEffect(() => {
        return () => {
            //limpieza
            cleanGetOffersAction();
            setLoadingUUIDAction(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useProductsCardsHook(params);

    return (
        <>
            <FlowsProductsComponent
                state={state}
                tabIndex={tabIndex}
                tabSubcategory={tabSubcategory}
                errorMessage={errorMessage}
                loadingCircular={loadingCircular}
                classicSorted={classicSorted}
                slimSorted={slimSorted}
                fitSorted={fitSorted}
                liteSorted={liteSorted}
                shareSorted={shareSorted}
                notShareSorted={notShareSorted}
                handleTabChange={handleTabChange}
                handleSubcategoryChange={handleSubcategoryChange}
                products={products}
                flow={"purchase"}
            />
            <Backdrop
                sx={(theme) => ({
                    color: "#fff",
                    zIndex: theme.zIndex.drawer + 1,
                })}
                open={isLoadingUUDI}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    getOffers: state.getOffers,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getOffersAction,
    cleanGetOffersAction,
    setLoadingUUIDAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PurchaseProductsPage);
