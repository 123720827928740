import { useEffect } from "react";

export const useProductsCardsHook = (props) => {
    const {
        products,
        classicSorted,
        setClassicSorted,
        slimSorted,
        setSlimSorted,
        fitSorted,
        setFitSorted,
        liteSorted,
        setLiteSorted,
        shareSorted,
        setShareSorted,
        notShareSorted,
        setNotShareSorted,
    } = props || {};
    useEffect(() => {
        products?.forEach((element, i) => {
            const sumGigaBytes =
                element.mobile_data +
                element.mobile_data_roaming +
                element.mobile_data_throttling;
            const gigaBytes = sumGigaBytes / element.gigabyte_divisor;

            /*** NO Planes - CLÁSICOS ***/
            if (element.name.toLowerCase().includes("Clásico")) {
                if (
                    !classicSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setClassicSorted((classicSortedArray) => [
                        ...classicSortedArray,
                        element,
                    ]);
                }
            }

            /*** NO Planes - SLIM ***/
            if (element.name.toLowerCase().includes("Slim")) {
                if (
                    !slimSorted?.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setSlimSorted((slimSortedArray) => [
                        ...slimSortedArray,
                        element,
                    ]);
                }
            }

            /*** NO Planes - FIT ***/
            if (element.name.toLowerCase().includes("Fit")) {
                if (
                    !fitSorted.find((ele) => ele.offer_id === element.offer_id)
                ) {
                    setFitSorted((fitSortedArray) => [
                        ...fitSortedArray,
                        element,
                    ]);
                }
            }

            /*** NO Planes - LITE ***/
            if (element.name.toLowerCase().includes("Lite")) {
                if (
                    !liteSorted.find((ele) => ele.offer_id === element.offer_id)
                ) {
                    setLiteSorted((liteSortedArray) => [
                        ...liteSortedArray,
                        element,
                    ]);
                }
            }

            if (!element.is_monthly && element.hotspot === true) {
                if (
                    !shareSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setShareSorted((shareSortedArray) => [
                        ...shareSortedArray,
                        element,
                    ]);
                }
            }

            if (!element.is_monthly && element.hotspot === false) {
                if (
                    !notShareSorted.find(
                        (ele) => ele.offer_id === element.offer_id
                    )
                ) {
                    setNotShareSorted((notShareSortedArray) => [
                        ...notShareSortedArray,
                        element,
                    ]);
                }
            }
        });
    }, [products]);

    return {};
};
