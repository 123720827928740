import React from "react";

//Material UI:
import { Box, Container } from "@mui/material";

//Components:
import LogoComponent from "../logo/LogoComponent";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

//Styles MUI:
import { gridItemBaseFirstSteps } from "./style-mui/baseFirstStepsStylesMUI";

function BaseFirstStepsComponent({ children }) {
    return (
        <Box sx={gridItemBaseFirstSteps}>
            <LogoComponent />

            <Container
                maxWidth={false}
                sx={{ p: 0, padding: "0px !important" }}
            >
                {children}
            </Container>

            <FormFooterComponent />
        </Box>
    );
}

export default BaseFirstStepsComponent;
