import { useEffect, useState } from "react";

//Material UI:
import { Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

//MUI X Charts:
import { Gauge, gaugeClasses } from "@mui/x-charts";

//Material UI Styles:
import {
    gridContainerGraphLabel,
    gridItemContainerGraphic,
    typographyGraphLabel,
    typographyItem,
    typographyValueItem,
} from "./styles-mui/graphicCardStylesMUI";

//CSS:
import "../../../../../../../../assets/styles/css/portal/pages/home/sections/active-offers/components/content-card/graphic-card.css";

function GraphicCardComponent({
    dataUnused,
    dataTotals,
    colorsList,
    graphLabel,
}) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const [dimensionsGreaterEqualMD, setdimensionsGreaterEqualMD] =
        useState(null);

    useEffect(() => {
        setdimensionsGreaterEqualMD(matches);
        return () => {};
    }, [matches]);

    const coveragePercentage = ((dataUnused * 100) / dataTotals) * 0.7;

    return (
        <Grid
            item
            md={6}
            xs={12}
            className="container-graphic-card"
            sx={gridItemContainerGraphic}
        >
            <Grid container className="chart-container" mb={2.2}>
                <Grid
                    item
                    xs={12}
                    className="chart"
                    sx={{
                        position: "relative",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: "-70px",
                    }}
                >
                    <svg width="0" height="0">
                        <defs>
                            <linearGradient
                                id="gradient"
                                x1="100%"
                                y1="0%"
                                x2="100%"
                                y2="100%"
                            >
                                <stop
                                    offset="0%"
                                    style={{
                                        stopColor: "#CC4ECC",
                                        stopOpacity: 1,
                                    }}
                                />
                                <stop
                                    offset="50%"
                                    style={{
                                        stopColor: "#7038C5",
                                        stopOpacity: 1,
                                    }}
                                />
                                <stop
                                    offset="100%"
                                    style={{
                                        stopColor: "#7038C5",
                                        stopOpacity: 1,
                                    }}
                                />
                            </linearGradient>
                        </defs>
                    </svg>
                    <Grid justifyItems={"center"} marginBottom={10}>
                        <Gauge
                            value={coveragePercentage}
                            startAngle={-110}
                            endAngle={110}
                            width={dimensionsGreaterEqualMD ? 350 : 300}
                            height={dimensionsGreaterEqualMD ? 350 : 300}
                            cornerRadius="50%"
                            text={({ value }) => `${value.toFixed(2)}%`}
                            innerRadius="75%"
                            sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: 30,
                                    fontFamily: "Poppins-SemiBold",
                                    transform: "translate(0px, -10px)",
                                },
                                [`& .${gaugeClasses.valueText} text`]: {
                                    fill: "#fff !important",
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                    fill: "url(#gradient)",
                                },
                            }}
                        />
                    </Grid>

                    <Grid container sx={gridContainerGraphLabel}>
                        <Grid item xs={12} sx={{ textAlign: "center" }}>
                            <Typography variant="h4" sx={typographyGraphLabel}>
                                {Math.round(dataUnused).toLocaleString("en")}{" "}
                                {graphLabel}
                            </Typography>
                            <Typography sx={{ fontSize: "15px", m: 0 }}>
                                Disponibles
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    className="data"
                    sx={{ mt: { xs: "-110px", md: "-110px" }, zIndex: 99 }}
                >
                    <Grid
                        item
                        xs={6}
                        sx={{
                            zIndex: 99,
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={typographyValueItem}
                            className="colored"
                        >
                            {Math.round(dataTotals).toLocaleString("en")}{" "}
                            {graphLabel}
                        </Typography>
                        <Typography variant="p" sx={typographyItem}>
                            Totales
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Typography
                            sx={typographyValueItem}
                            className="colored"
                        >
                            {Math.round(dataTotals - dataUnused).toLocaleString(
                                "en"
                            )}{" "}
                            {graphLabel}
                        </Typography>
                        <Typography variant="p" sx={typographyItem}>
                            Consumidos
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default GraphicCardComponent;
